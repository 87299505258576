import { log } from '@craco/craco/lib/logger';
import { getDetailSections } from '../../api';

const state = {
    state: {
        loading: true,
        models: [],
        customerOpinions: [],
    },
    reducers: {
      setLoading(state, payload) {
        return {
            ...state,
            loading: payload
        }
      },
      setDetailSections(state, payload) {
        return {
            ...state,
            ...payload
        }
      }
    },
    effects: (dispatch) => ({
      async getDetailSections(slug) {
        dispatch.detailSections.setLoading(true);
        return getDetailSections(slug)
          .then(({ data }) => {
            console.log(data.mainSliderItems);
            dispatch.detailSections.setDetailSections({
                mainSliderItems: data.mainSliderItems,
                customerOpinions: data.customerOpinions,
                models: data.models,
                financingBanner: data.financingBanner,
                media: data.media,
                banner: data.banner
            })
            dispatch.detailSections.setLoading(false);
          })
      },
    }),
};

export default state;