import { getKnowUsSections } from '../../api';

const state = {
    state: {
        loading: true,
    },
    reducers: {
      setLoading(state, payload) {
        return {
            ...state,
            loading: payload
        }
      },
      setKnowUsSections(state, payload) {
        return {
            ...state,
            ...payload
        }
      }
    },
    effects: (dispatch) => ({
      async getKnowUsSections(slug) {
        dispatch.knowUsSections.setLoading(true);
        return getKnowUsSections(slug)
          .then(({ data }) => {
            dispatch.knowUsSections.setKnowUsSections({
                customerOpinions: data.customerOpinions,
                models: data.models,
                mainBanner: data.mainBanner,
                blogBanner: data.blogBanner,
                homeSection4: data.homeSection4,
                slider: data.slider
            })
            dispatch.knowUsSections.setLoading(false);
          })
      },
    }),
};

export default state;