import PropTypes from 'prop-types';

const ResponsiveImage = ({
    mobileSrc,
    desktopSrc,
    customClass,
    ...htmlProps
}) => {
    return (
        <picture className={customClass}>
            {desktopSrc && <source srcSet={desktopSrc} media="(min-width: 1024px)" />}
            <img className="h-full" src={mobileSrc} alt={htmlProps?.alt || 'No description'} />
        </picture>
    )
}

ResponsiveImage.propTypes = {
    mobileSrc: PropTypes.string.isRequired,
    desktopSrc: PropTypes.string,
    customClass: PropTypes.string,
}

ResponsiveImage.defaultProps = {
    desktopSrc: '',
    customClass: '',
}

export default ResponsiveImage;