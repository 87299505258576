import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}service-appointment`;

/**
 * @description Sends contact emails
 * @param {Object} data
 * @author Isaac Vazquez
*/
export const sendAppointment = (data) => {
    return axios.post(
        API_URL,
        { ...data }
    )
};