import { useState, useEffect, useContext } from 'react';
import axios from 'axios';

const API_AUTODEAL_URL = `${process.env.REACT_APP_AUTODEAL_API_URL}`;
const API_AUTODEAL_USER = `${process.env.REACT_APP_AUTODEAL_API_USER}`;
const API_AUTODEAL_PASSWORD = `${process.env.REACT_APP_AUTODEAL_API_PASSWORD}`;

export default function Autodeal () {
    const [ token, setToken ] = useState('');
    const [ models, setModels ] = useState([]);
    const [ years, setYears ] = useState([]);
    const [ versions, setVersions ] = useState([]);

 const getTokenAutodeal = async () => {
    const url = API_AUTODEAL_URL + 'login';

    const postData = {
        email: API_AUTODEAL_USER,
        password: API_AUTODEAL_PASSWORD
    };

    await axios.post(url, postData, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
    })
    .then(response => {
        if (response.data.token) {
            console.log(response.data.token);
            setToken('response.data.token')
        }
    })
    .catch(error => {
        console.error(error);
        return {};
    });
};

 const getModels =  async () => {
    const url = API_AUTODEAL_URL + 'mi-autodeal/intelimotor/models/1';

    await getTokenAutodeal();

    console.log('accessToken');
    console.log(token);

    const { data } = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': token,
        },
    })
    let modelsValue = data.data.map(item => {
      return { label: item.name, value: item.id }
    })

    setModels(modelsValue)
};

 const getYears = async (modelId) => {
    const url = API_AUTODEAL_URL + `mi-autodeal/intelimotor/years/1/${modelId}`

    const accessToken = await getTokenAutodeal();

    const { data } = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': accessToken,
        },
    })
    let yearsValue = data.data.map(item => {
        return { label: item.name, value: item.id }
    })

    setYears(yearsValue)
};

 const getVersions = async (modelId, year) => {
    const url = API_AUTODEAL_URL + `mi-autodeal/intelimotor/versions/1/${modelId}/${year}`;

    const accessToken = await getTokenAutodeal();

    const { data } = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': accessToken,
        },
    })
    let versionsValue = data.data.map(item => {
      return { value: item.id, label: item.name }
    })

    setVersions(versionsValue)
};
return {
    models,
    years,
    versions,
    getModels,
    getYears,
    getVersions
  }
}