import PropTypes from 'prop-types';
import ModelCard from './ModelCard';
import { schedule_driving_test } from '../../assets/img/index';

const FeaturedModels = ({ models = [] }) => {
  return (
    <div className="featured-models flex flex-wrap w-full overflow-hidden">
      {models.map((model, i) =>
        <div 
            className="featured-card-wrapper w-full lg:w-1/2"
            key={i}>
            <ModelCard model={model} />
        </div>
      )}
      {
        (models.length % 2 !== 0) && <div key={models.length + 1} className="featured-card-wrapper w-full lg:w-1/2 bg-black"></div>
      }
      
      {/* <div className="w-full flex flex-col lg:flex-row flex-col-reverse">
        <div className="featured-card-wrapper w-full lg:w-1/2 flex justify-center z-20">
          <a href="https://wa.me/5213313981064?text=Hola!%20Me%20gustaría%20recibir%20información" target="_blank" rel="noreferrer">
            <img src={schedule_driving_test} alt="Schedule driving test" />
          </a>
        </div>
      </div> */}
    </div>
  );
}

FeaturedModels.propTypes = {
    models: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        subtitle: PropTypes.string,
        secondaryText: PropTypes.string,
        desktopImage: PropTypes.string,
        mobileImage: PropTypes.string,
        createAt: PropTypes.string,
        updatedAt: PropTypes.string,
        model: PropTypes.shape({
          id: PropTypes.string,
          slug: PropTypes.string,
          model: PropTypes.string,
          createdAt: PropTypes.string,
          updatedAt: PropTypes.string,
        })
      })
    ),
  }
  
  FeaturedModels.defaultProps = {
    models: []
  }

export default FeaturedModels;