import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}front`;

/**
 * @description Gets all detail sections info
 * @param {string} slug
 * @author Isaac Vazquez
*/
export const getDetailSections = (slug) => {
    const url = `${API_URL}/detail/${slug}`
    return axios.get(url);
};