import { useState, createRef, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as LeftArrow } from '../../assets/icons/left-arrow.svg';
import { ReactComponent as RightArrow } from '../../assets/icons/right-arrow.svg';
import SlideInfo from './SlideInfo';

import './Carousel.css';

// const DEFAULT_SLIDE_IMAGE = 'https://via.placeholder.com/1280x720.png';

const Carousel = ({ slides }) => {
  const [currentImage, setCurrentImage] = useState(0);

  const refs = useMemo(() =>
    slides.reduce((acc, val, i) => {
      acc[i] = createRef();
      return acc;
    }, {}), [slides]);

  const scrollToImage = useCallback((i) => {
    setCurrentImage(i);
    refs[i].current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  }, [setCurrentImage, refs]);

  const totalImages = useMemo(() => slides.length, [slides.length])
  
  const nextImage = () => {
    currentImage >= totalImages - 1 ? scrollToImage(0) : scrollToImage(currentImage + 1);
  };

  const previousImage = () => {
    currentImage === 0 ? scrollToImage(totalImages - 1) : scrollToImage(currentImage - 1);
  };

  return (
    <div className="carousel acura_wrapper_xl w-full flex mx-auto">
      <div className="relative justify-center w-screen h-screen lg:h-full flex">
          <div className="w-full h-full absolute" style={{background: 'rgba(0, 0, 0, .3)'}} />
          <div className="inline-flex overflow-x-hidden"> {/*  mt-16 lg:mt-0  */}

            {
              slides.map((slide, i) => (
                <div className="w-full flex-shrink-0" ref={refs[i]} key={slide.model.slug}>
                  <picture className="w-screen object-cover lg:object-contain h-screen lg:h-full">  
                      {slide.desktopImage && <source srcSet={slide.desktopImage} media="(min-width: 768px)" />}
                      <img className="w-screen object-cover h-screen lg:h-full" src={slide.mobileImage && slide.mobileImage} alt={slide.model} />
                  </picture>
                </div>
              ))
            }

            <SlideInfo info={slides[currentImage]} />

            <button 
              onClick={previousImage}
              className="control-buttons absolute lg:block bottom-0 lg:bottom-auto lg:top-1/2 right-1/2 lg:right-auto transform -translate-y-1/2 cursor-pointer border-2 border-white font-bold text-white lg:ml-6 hover:border-3 active:bg-white active:text-black z-20">
              <LeftArrow />
            </button>

            <button 
              onClick={nextImage}
              className="control-buttons absolute lg:block bottom-0 lg:bottom-auto lg:top-1/2 left-1/2 lg:left-auto transform -translate-y-1/2 right-0 cursor-pointer border-2 border-white font-bold text-white lg:mr-6 hover:border-3 active:bg-white active:text-black z-20">
              <RightArrow />
            </button>
          </div>
      </div>
    </div>
  );
}

Carousel.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      secondaryText: PropTypes.string,
      desktopImage: PropTypes.string,
      mobileImage: PropTypes.string,
      createAt: PropTypes.string,
      updatedAt: PropTypes.string,
      model: PropTypes.shape({
        id: PropTypes.string,
        slug: PropTypes.string,
        model: PropTypes.string,
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
      })
    })
  ),
}

Carousel.defaultProps = {
  slides: []
}

export default Carousel;