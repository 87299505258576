import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ReactComponent as Visibility } from '../../assets/icons/visibility.svg';
import { ReactComponent as UserCommentWhite } from '../../assets/icons/user-comment-white.svg';
import './ModelCard.css';

const ModelCard = ({ model }) => (
    <div className="featured-model-card car_card flex items-center">
        <div className="w-full text-center text-white">
            {/* <ResponsiveImage
                mobileSrc={model?.mobileImage}
                customClass="acura_card_img"
                alt="Model" /> */}

            <picture className={`${model.model.slug} featured-model-card__image`}>
                {model?.desktopImage && <source srcSet={model?.desktopImage} media="(min-width: 1024px)" />}
                <img className="h-full" src={model?.mobileImage} alt={model?.model?.model} />
            </picture>
            
            <div className="featured-model-card__details flex flex-col justify-center items-center font-avenir text-center">
                <h4 className="font-bold text-xl md:text-4xl lg:text-2xl xl:text-4xl leading-none uppercase pt-2">{model?.model?.model}</h4>
                <p className="font-thin text-xs md:text-xl xl:text-2xl pb-2">{model?.subtitle}</p>              
            </div>
            
            <div className="featured-model-card__overlay">
                <div className="flex flex-col justify-center items-center w-full h-full">
                    <div className="font-avenir py-8 text-center">
                        <p className="font-bold text-xl lg:text-3xl leading-none uppercase">{model?.model?.model}</p>
                        <p className="font-thin text-md lg:text-xl">{model?.subtitle}</p>              
                    </div>
                    <div className="flex items-center justify-center">
                        <Link to={`/${model?.model?.slug}`} className="flex items-center text-xs md:text-base font-acura bg-white hover:opacity-80 font-bold text-black px-8 lg:px-16 py-3 transition duration-300 ease-in-out mr-3">
                            Ver auto
                            <Visibility className="ml-4" />
                        </Link>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={`https://wa.me/5213322577807?text=Hola!%20Me%20gustaría%20realizar%20una%20prueba%20del%20modelo%20${model?.model?.model}.`}
                            className="flex items-center text-xs md:text-base font-acura bg-primary hover:bg-blue-100 active:bg-primary focus:bg-primary font-bold text-white px-3 lg:px-8 py-3 transition duration-300 ease-in-out ml-3">
                            Probar en agencia
                            <UserCommentWhite className="ml-4" />
                        </a>                              
                    </div>
                </div>
            </div>
        </div>
    </div>
)

ModelCard.propTypes = {
    model: PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
            subtitle: PropTypes.string,
            secondaryText: PropTypes.string,
            desktopImage: PropTypes.string,
            mobileImage: PropTypes.string,
            createAt: PropTypes.string,
            updatedAt: PropTypes.string,
            model: PropTypes.shape({
                id: PropTypes.string,
                slug: PropTypes.string,
                model: PropTypes.string,
                createdAt: PropTypes.string,
                updatedAt: PropTypes.string,
            })
        }).isRequired
}

export default ModelCard;