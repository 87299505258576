import axios from 'axios';

const API_AUTODEAL_URL = `${process.env.REACT_APP_AUTODEAL_API_URL}`;
const API_AUTODEAL_USER = `${process.env.REACT_APP_AUTODEAL_API_USER}`;
const API_AUTODEAL_PASSWORD = `${process.env.REACT_APP_AUTODEAL_API_PASSWORD}`;


/**
 * @description Sends contact emails
 * @param {Object} data
 * @author 
*/
export const getTokenAutodeal = async () => {
  const url = API_AUTODEAL_URL + 'login';

  const postData = {
    email: API_AUTODEAL_USER,
    password: API_AUTODEAL_PASSWORD
  };

  try {
    const response = await axios.post(url, postData, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    });

    if (response.data.token) {
      return response.data.token;
    }
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const getModels = async () => {
  try {
    const accessToken = await getTokenAutodeal();
    const url = API_AUTODEAL_URL + 'mi-autodeal/intelimotor/models/1';

    const { data } = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'bearer ' + accessToken,
      },
    });

    const models = data.data.map(item => {
      return { label: item.name, value: item.id }
    })

    return models;

  } catch (error) {
    console.error(error);
    // Handle error appropriately
    throw error;
  }
};

export const getYears = async (modelId) => {
  try {
    const url = API_AUTODEAL_URL + `mi-autodeal/intelimotor/years/1/${modelId}`

    const accessToken = await getTokenAutodeal();

    const { data } = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'bearer ' + accessToken,
        },
    })
    return data.data.map(item => {
        return { label: item.name, value: item.id }
    })

  } catch (error) {
    console.error(error);
    // Handle error appropriately
    throw error;
  }

};

export const getVersions = async (modelId, year) => {
  try {
    const url = API_AUTODEAL_URL + `mi-autodeal/intelimotor/versions/1/${modelId}/${year}`;

    const accessToken = await getTokenAutodeal();

    const { data } = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'bearer ' + accessToken,
        },
    })
    return data.data.map(item => {
      return { value: item.id, label: item.name }
    })

  } catch (error) {
    console.error(error);
    // Handle error appropriately
    throw error;
  }
};