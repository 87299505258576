import PropTypes from 'prop-types';
import bg from '../../assets/img/address_change.jpg';
import './index.css';

function ChangeAddressDialog({
    onClose,
}) {
    return (
        <div className="bg-white rounded-md relative popup-animation" style={{ width: "80%" }}>
            <button type="button" className="absolute right-0 text-white bg-black px-3 py-1 text-sm" onClick={onClose}>Cerrar</button>
            <img src={bg} alt="Imagen cambio de domicilio" />
        </div>
    )
}

ChangeAddressDialog.propTypes = {
    onClose: PropTypes.func,
}

ChangeAddressDialog.defaultProps = {
    onClose: () => {}
}

export default ChangeAddressDialog;