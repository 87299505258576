import { getHomeSections } from '../../api';

const state = {
    state: {
        loading: true,
        mainSliderItems: [],
        characteristics: [],
        customerOpinions: [],
        banner1: null,
        slider2: null,
        bannerAppointment: null,
        footer: null,
        newModels: []
    },
    reducers: {
      setLoading(state, payload) {
        return {
            ...state,
            loading: payload
        }
      },
      setHomeSections(state, payload) {
        return {
            ...state,
            ...payload
        }
      }
    },
    effects: (dispatch) => ({
      async getHomeSections() {
        dispatch.homeSections.setLoading(true);
        return getHomeSections()
          .then(({ data }) => {
            dispatch.homeSections.setHomeSections({
              mainSliderItems: data.mainSliderItems,
              characteristics: data.homeCharacteristics,
              banner1: data.banner1,
              bannerAppointment: data.bannerAppointment,
              customerOpinions: data.customerOpinions,
              models: data.models,
              secondarySlider: data.secondarySlider,
              newModels: data.newModels,
            })
            dispatch.homeSections.setLoading(false);
          })
      },
    }),
};

export default state;