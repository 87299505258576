import PropTypes from 'prop-types';
import SlideButtons from './SlideButtons';

const SlideInfo = ({ info }) => (
    <div className="slider-info absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-28 lg:-translate-y-10 xl:-translate-y-20">
        <div className="flex flex-col items-center">
            <h2 className="font-avenir font-bold text-2xl lg:text-6xl xl:text-6xl lg:text-4xl leading-none pb-2 uppercase text-center text-white">{info.title}</h2>
            <p
                className="text-white font-avenir font-thin text-center lg:text-3xl lg:text-2xl xl:text-3xl pb-3 lg:pb-6">
                {info.subtitle}
            </p>
            
            <SlideButtons modelSlug={info.model.slug} model={info.model.model} />
        </div>
    </div>
)

SlideInfo.propTypes = {
    info: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        subtitle: PropTypes.string,
        secondaryText: PropTypes.string,
        desktopImage: PropTypes.string,
        mobileImage: PropTypes.string,
        createAt: PropTypes.string,
        updatedAt: PropTypes.string,
        model: PropTypes.shape({
            id: PropTypes.string,
            slug: PropTypes.string,
            model: PropTypes.string,
            createdAt: PropTypes.string,
            updatedAt: PropTypes.string,
        })
    })
}

export default SlideInfo;