import PropTypes from 'prop-types';
import { ReactComponent as Visibility } from '../../assets/icons/visibility.svg';
import { ReactComponent as BuildingWhite } from '../../assets/icons/building-white.svg';

const SlideButtons = ({ modelSlug, model }) => (
    <div className="flex flex-col md:flex-row">
        <a
            href={`/${modelSlug}`}
            className="text-xxs md:text-base flex items-center justify-center font-acura bg-white font-bold text-black px-2 lg:px-8 py-3 transition duration-300 ease-in-out md:mx-6 cursor-pointer">
            Ver auto
            <Visibility className="ml-2 lg:ml-4" />
        </a>
        <a
            href={`https://wa.me/3313981064?text=Hola!%20Me%20gustaría%20recibir%20información%20sobre%20el%20modelo%20${model}.`}
            target="_blank"
            rel="noreferrer"
            style={{backgroundColor: '#747474'}}
            className="text-xxs md:text-base w-full md:w-auto mt-3 md:mt-0 flex items-center font-acura border-2 lg:border-none bg-black hover:bg-gray font-bold text-white px-2 lg:px-8 py-3 transition duration-300 ease-in-out cursor-pointer">
            Probar en agencia
            <BuildingWhite className="ml-2 lg:ml-4" />
        </a>
    </div> 
)

SlideButtons.propTypes = {
    modelSlug: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
}

export default SlideButtons;