import { useRef } from 'react';
import { useEffect } from 'react';
import { createContext, useState } from 'react';

export const DialogContext = createContext({});

const DialogProvider = ({ children }) => {
    const [dialogContent, setDialogContent] = useState(false);
    const dialogRef = useRef();

    const handleKeyUp = (e) => {
        if (e.key === 'Escape') {
            setDialogContent(null);
        }
    }

    useEffect(() => {
        if (dialogContent) {
            dialogRef.current.addEventListener('keyup', handleKeyUp, true);
        } else if (dialogRef?.current) {
            dialogRef.current.removeEventListener('keyup', handleKeyUp, true);
        }
    }, [dialogContent]);

    return (
        <DialogContext.Provider value={{ dialogContent, setDialogContent }}>
            {dialogContent && <div ref={dialogRef} className="fixed w-screen h-screen bg-black bg-opacity-50 z-50 flex justify-center items-center">
                {dialogContent}
            </div>}
                {children}
        </DialogContext.Provider>
    );
}

export default DialogProvider;