import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}front`;

/**
 * @description Gets all services sections info
 * @author Isaac Vazquez
*/
export const getServicesSections = () => {
    const url = `${API_URL}/services`
    return axios.get(url);
};