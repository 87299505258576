import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}front`;

/**
 * @description Gets all preowned sections info
 * @author Isaac Vazquez
*/
export const getPreownedSections = () => {
    const url = `${API_URL}/preowned`
    return axios.get(url);
};