import { getPreownedSections } from '../../api';

const state = {
    state: {
        loading: true,
    },
    reducers: {
      setLoading(state, payload) {
        return {
            ...state,
            loading: payload
        }
      },
      setPreownedSections(state, payload) {
        return {
            ...state,
            ...payload
        }
      }
    },
    effects: (dispatch) => ({
      async getPreownedSections(slug) {
        dispatch.preownedSections.setLoading(true);
        return getPreownedSections(slug)
          .then(({ data }) => {
            dispatch.preownedSections.setPreownedSections({
                preownedBanner1: data.preownedBanner1,
                preownedBanner2: data.preownedBanner2,
                financingBanner: data.financingBanner,
                customerOpinions: data.customerOpinions,
            })
            dispatch.preownedSections.setLoading(false);
          })
      },
    }),
};

export default state;