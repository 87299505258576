import { getServicesSections } from '../../api';

const state = {
    state: {
        loading: true,
    },
    reducers: {
      setLoading(state, payload) {
        return {
            ...state,
            loading: payload
        }
      },
      setServicesSections(state, payload) {
        return {
            ...state,
            ...payload
        }
      }
    },
    effects: (dispatch) => ({
      async getServicesSections(slug) {
        dispatch.servicesSections.setLoading(true);
        return getServicesSections(slug)
          .then(({ data }) => {
            dispatch.servicesSections.setServicesSections({
                customerOpinions: data.customerOpinions,
                models: data.models,
                mainBanner: data.mainBanner,
                homeBanner: data.homeBanner,
                contactAdvisor: data.contactAdvisor,
                sparePartsBanner: data.sparePartsBanner,
                slider: data.slider,
            })
            dispatch.servicesSections.setLoading(false);
          })
      },
    }),
};

export default state;